import React, { useState, useEffect, useRef } from 'react';
import './App.css'
import axios from 'axios';
import { configuration } from "./helper/appConfig";

const App = () => {

  const chatContentRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([
    { text: 'This is AMA Support! Nice to meet you!', type: 'support' },
    { text: 'This is your virtual assistant here to help with any questions you might have.', type: 'support' },
  ]);
  
  const [newMessage, setNewMessage] = useState('');

  const toggleChatbot = () => {
    setIsOpen(!isOpen);
  };
  
  useEffect(() => {
   
    const chatContent = chatContentRef.current;
    if (isOpen && chatContent) {
      console.log(chatContent.scrollTop,chatContent.scrollHeight,'.......')
      chatContent.scrollTop = chatContent.scrollHeight;
    }
  }, [messages,isOpen]);

  
  var results = [];
  useEffect(() => {

    const savedMessages = localStorage.getItem('chatData');
    if (savedMessages) {
      setMessages(JSON.parse(savedMessages));
    }
  }, []);

  const handleSendMessage = async () => {
    if (newMessage.trim() !== '') {
      setMessages(prevMessages => [
        ...prevMessages,
        { text: newMessage, type: 'user' }
      ]);
      setNewMessage('');
      setLoading(true);

    }
    const url = configuration.chat;
    let payload = {
      prompt: newMessage
    }
    await axios.post(url, payload)
      .then(async (res) => {
        // await setMessages(prevMessages => [
        //   ...prevMessages,
        //   { text: res.data.outputText, type: 'support' }
        // ]);

        await setMessages(prevMessages => {
          // const updatedMessages = [
          //   ...prevMessages,
          //   { text: res.data.outputText, type: 'support' }
          // ];
          const updatedMessages = prevMessages.filter(msg =>  msg?.type !== 'error');
          updatedMessages.push({ text: res?.data?.outputText, type: 'support' });
          localStorage.setItem('chatData', JSON.stringify(updatedMessages));
          
          return updatedMessages;
        });

        setLoading(false);
      })
      .catch(async (err) => {
       
        let errorMessage
        if(err?.response?.status == 500){
          errorMessage = "The chatbot is currently offline. Please try again later.";
        }else{
          console.log(err,err?.response?.data?.message,"err?.response?.data?.message")
          errorMessage = err?.response?.data?.message|| 'Something went wrong.';
        }
        await setMessages(prevMessages => {
          const updatedMessages = [
            ...prevMessages,
            { text: errorMessage, type: 'error' }
          ];
          
          return updatedMessages;
        });
        setLoading(false);
      })

  };

 

  return (
    <div id="chatbot-container">

      {/* <iframe src="https://chatbot.hellotars.com/conv/NJba4f/" style="width: 400px;height: 620px;box-shadow: 0 20px 80px rgba(0,0,0,.6);border-radius: 5px;overflow: hidden;border: none;"></iframe> */}
      {!isOpen ?
        <div id="chatbot-button" onClick={toggleChatbot}>
          <img width="50" height="50" src="https://img.icons8.com/fluency-systems-regular/50/chat-message.png" alt="chat-message" />
        </div>
        : null}

      <div id="chatbot-window" className={isOpen ? 'open' : 'close'}>
        <div id="chatbot-header">
          <h3>AMA Assistant Bot</h3>
          <button id="close-button" onClick={toggleChatbot}>&#10006;</button>
        </div>

        <div id="chatbot-content" ref={chatContentRef}>
          {messages.map((message, index) => (
            <div
              key={index}
              className={`message ${message.type}`}
            >
              {message.text}
            </div>
          ))}
          {loading && (
            <div className="message support">
              <em>Loading...</em>
            </div>
          )}
        </div>

        <div id="chatbot-footer">
          {!loading?<input
            type="text"
            placeholder="Send a message.."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          />:<div>AMA Typing Message....</div>}
          <button id="send-button" onClick={handleSendMessage} disabled={newMessage.trim() ===""?true:false}>
            <img width="48" height="48" src="https://img.icons8.com/color/48/circled-right--v1.png" alt="circled-right--v1" />
          </button>
        </div>
      </div>
    </div>
  );

}

export default App;